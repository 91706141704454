<template>
<div>
  <div class="components-wrap" v-if="needMe">
    <div class="top-change" @click="goBack">
      <div class="l-where">合同详情</div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="have-two">
      <div class="l-del">
        <div class="nav-title">合同详情</div>
        <Table :columns="columns" :data="contractData"   style="margin-top: 20px">
        </Table>
      </div>
      <div class="r-del">
        <div class="nav-title">合同附件</div>
        <div  v-if="moreArr.accessories ">
          <div class="file-group flex"  v-if="moreArr.accessories.accessories_url">
            <div class="file-label">文件附件:</div>
            <div class="file-val" @click="openWindow(moreArr.accessories.accessories_url)"><img style="width:50px;height:50px" src="https://susuyunqian.hulianzaowu.com/img/backstage/diagram_file.png" /></div>
          </div>
          <div class="file-group flex" v-if="moreArr.accessories.accessories_img.length > 0 ">
            <div class="file-label">图片附件:</div>
            <div class="pic-list flex">
              <div class="pic-val" v-for="(item,index) in moreArr.accessories.accessories_img" :key="index">
                <img :src="item" @click="openWindow(item)">
              </div>
            </div>
          </div>
        </div>
        <div v-else style="font-size: 14px;color: #8a8f97;text-align: center;margin-top: 30px">暂无附件</div>
      </div>
    </div>
    <div class="see-more">
      <div class="nav-title">参与方</div>
      <Table :columns="columnsPart" :data="dataPart" style="margin-top: 20px">
        <template slot-scope="{ row }" slot="type">
          <div>
            {{row.type==1?'企业':'个人'}}
          </div>
        </template>
        <template slot-scope="{ row }" slot="status">
          <div>
            {{row.status==1?'已签署':'未签署'}}
          </div>
        </template>
        <template slot-scope="{ row }" slot="showType">
          <div v-if="row.requirement == 1">企业公章</div>
          <div v-if="row.requirement == 2">经办人签署</div>
          <div v-if="row.requirement == 3">企业公章和经办人签署</div>
          <div v-if="row.requirement == 4">短信验证</div>
          <div v-if="row.requirement == 5">企业公章和短信验证</div>
          <div v-if="row.requirement == 6">经办人签署和短信验证</div>
          <div v-if="row.requirement == 7">企业公章、需要经办人签署、需要短信验证</div>
        </template>
      </Table>
      <div class="card-btn flex-c">
        <div class="table-btn blue" @click="seeAlot">合同预览</div>
<!--        <div class="table-btn edit" @click="goBack">退出</div>-->
      </div>
    </div>
    <div class="modal-pop" v-if="show_date">
      <div class="pop"></div>
      <div class="msg-add fixed">
        <div class="flex">
          <div class="label">截止日期：</div>
          <div class="r-find ">
            <DatePicker type="date"  @on-change="changeTime"   :options="date_options" :start-date="tmp.enddate" v-model="submitForm.enddate" style="width: 200px"></DatePicker>
          </div>
        </div>
        <div class="give">
          <div class="cancel flex-c" @click="cancel_date">取 消</div>
          <div class="require flex-c" @click="sure_date">确 定</div>
        </div>
      </div>
    </div>
  </div>
    <allcontracts @titleSync="goToView" ref="contracts" ></allcontracts>  
</div>
</template>

<script>

import { modifyContractTime } from '../../../api/interFace'
import allcontracts from './allcontracts'
export default {
  name: "contractDel",
  components: {
    allcontracts,
  },
  data(){
    return{
      date_options:{
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - 86400000;
        }
      },
      show_date:false,
      submitForm:{
        enddate:"",
        id:0
      },
      tmp:{
        enddate:""
      },
      columns: [
        {
          title: '合同ID',
          key: 'id',
          width:100
        },
        {
          title: '合同名称',
          key: 'contract_name',
          align: 'center'
        },
        {
          title: '操作时间',
          key: 'create_time',
          align: 'center',
          width:180
        },
        {
          title: '认证',
          slot: 'type',
          align: 'center',
          width: 100,
          render: (h, params) => {
            let auth_text , auth_color
            if(params.row.authentication === 1){
              if(params.row.status == '已签署'){
                auth_text = '已认证'
                auth_color = "#515a6e"
              }else {
                auth_text = '需要认证'
                auth_color = "#FF0000"
              }
            }else{
              auth_text = '无需认证'
              auth_color = "#515a6e"
            }
            return h('div', [
              h('span', {
                style: {color: auth_color},
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, auth_text)
            ]);
          }
        },
        {
          title: '短信通知',
          key: 'status',
          align: 'center',
          width:100,
          render: (h, params) => {
            let auth_text;
            let auth_color;
            if(params.row.sms_notice === 1){
                auth_text = '开启'
                auth_color = "#000000"
            }else{
              auth_text = '未开启'
              auth_color = "#ff0000"
            }
            return h('div', [
              h('span', {
                style: {color: auth_color},
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, auth_text)
            ]);
          }
        },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          width:100
        },
        {
          title: '签约截止日期',
          align: 'center',
          render:(h, params)=>{
            let text = params.row.start_time+'-'+params.row.end_time
            let style_obj = {}
            if (params.row.status_ == 0) {
              style_obj = {
                cursor:'pointer'
              }
            }
            this.tmp.enddate = new Date(params.row.end_time)
            this.submitForm.enddate = params.row.end_time
            return h('div', [
              h('span', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style:style_obj,
                on:{
                  click:()=>{
                      if (params.row.status_ == 0) {
                          this.show_date = true;
                      }
                  }
                }
              }, text)
            ]);
          }
        },
        {
          title: '合同有效日期',
          align: 'center',
          render:(h, params)=>{
            let text = ''
            if(params.row.start_expired == undefined ||params.row.start_expired=='0000-00-00'){
                text = '暂无有效日期'
            }else{
              text = params.row.start_expired+'-'+params.row.end_expired
            }
            let style_obj = {}
            if (params.row.status_ == 0) {
              style_obj = {
                cursor:'pointer'
              }
            }
            return h('div', [
              h('span', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style:style_obj,                
              }, text)
            ]);
          }
        }
      ],
      contractData:[],
      columnsPart: [
        {
          title: '参与方',
          key: 'participants',
          align: 'center'
        },
        {
          title: '参与主体',
          slot: 'type',
          align: 'center'
        },
        {
          title: '企业名称',
          key: 'prise_name',
          align: 'center'
        },
        {
          title: '经办人姓名',
          key: 'name',
          align: 'center'
        },
        {
          title: '账号/手机',
          key: 'phone',
          align: 'center'
        },
        {
          title: '应付款',
          key: 'money',
          align: 'center'
        },
        {
          title: '认证',
          key: 'authentication',
          align: 'center',
          render: (h, params) => {
            let auth_text , auth_color
            if(this.moreArr.authentication === 1){
              if(params.row.status == 1){
                auth_text = '已认证'
                auth_color = "#515a6e"
              }else {
                auth_text = '需要认证'
                auth_color = "#FF0000"
              }
            }else{
              auth_text = '无需认证'
              auth_color = "#515a6e"
            }
            return h('div', [
              h('span', {
                style: {color: auth_color},
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, auth_text)
            ]);
          }
        },
        {
          title: '签署条件',
          slot: 'showType',
          align: 'center'
        },
        {
          title: '是否签署',
          slot: 'status',
          align: 'center'
        }
      ],
      dataPart:[],
      needMe:false,
      moreArr:{},
    }
  },
  created() {
  },
  mounted() {
  },
  methods:{
    modifyContractTime(){
      modifyContractTime(this.submitForm).then(res=>{
        this.$Message.success(res.msg)
        this.contractData[0].end_time = this.submitForm.enddate
      })
    },
    cancel_date(){
      this.show_date = false;
    },
    sure_date(){
      this.show_date = false;
      this.modifyContractTime();
    },
    goBack(){
      this.needMe = false
      this.$emit('goBack')
    },
    seeAlot(){
      this.$nextTick(()=>{
        this.needMe = false
        console.log('this.moreArr',this.moreArr)
        this.$refs.contracts.openIt(this.moreArr)
      })
    },
    goToView(){
      this.needMe = true

    },
    openWindow(path){
      window.open(path)
    },

    changeTime(e) {
      this.submitForm.enddate = e
    },
    showDetail(row){
      console.log('1')
      this.contractData = []
      this.needMe = true
      this.dataPart = row.data.participant
      this.contractData.push(row.data)
      this.moreArr = row.data
      this.submitForm.id = this.contractData[0].id
      // console.log(this.moreArr)
      // let val = this.moreArr.accessories_url
      // this.moreArr.accessories =val ? val.split('/')[val.split('/').length - 1] : ''
    },

  }
}
</script>

<style scoped lang="scss">
.components-wrap {
  .top-change {
    cursor: pointer;
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    height: 60px;
    margin-bottom: 20px;
    .l-where {
      //position: absolute;
      //left: 30px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #1B2742;
      span {
        color: #4877E8;
      }
    }
    .r-back {
      width: 200px;
      text-align: end;
      cursor: pointer;
      .iconfont {
        //position: absolute;
        //right: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 26px;
        cursor: pointer;

        &:hover {
          color: #4877E8;
        }
      }
    }
  }
  .have-two {
    display: flex;
    justify-content: space-between;
  }
  .l-del {
    width: 70%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 20px;
    box-sizing: border-box;
  }
  .r-del {
    width: 29%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 20px;
    box-sizing: border-box;
    .file-group {
      margin-top: 20px;
      .file-label {
        font-size: 16px;
        font-weight: bold;
        color: #545F78;
      }
      .file-val {
        font-size: 15px;
        font-weight: bold;
        color: #4877E8;
        margin-left: 5px;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
      .pic-list {
        flex: 1;
        flex-wrap: wrap;
        .pic-val {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          cursor: pointer;
          margin-left: 8px;
          margin-top: 5px;
          img{
            width: 100%;
            height: 100%;
            border-radius: 4px;

          }
        }
      }
    }
  }
  .see-more {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 20px;
    padding: 20px 20px;
    box-sizing: border-box;
    .card-btn {
      margin-top: 60px;
      .blue {
        margin-right: 80px;
      }
    }
  }

  .modal-pop {
    .msg-add {
      width: 500px;
      background: white;
      border-radius: 8px;
      padding: 40px 30px 20px 30px;
      box-sizing: border-box;
      .msg-l {
        font-size: 14px;
        color: #333333;
      }
      .msg-i {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .give {
    display: flex;
    justify-content: right;
    margin-top: 20px;
  }
  .require {
    width: 100px;
    height: 34px;
    background: #4877E8;
    border: 1px solid #4877E8;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .cancel {
    width: 100px;
    height: 34px;
    color: #1B2742;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 20px;
    &:hover {
      color: #4877E8;
    }
  }
}


</style>
